<template>
  <div>
    <el-dialog
      class="psycho-stu-record-dialog"
      :title="`${preTitle}咨询状态设置`"
      :visible.sync="show"
      :close-on-click-modal="false"
      :before-close="cancel"
      width="720px"
    >
      <el-descriptions :column="2" border>
        <el-descriptions-item label-class-name="descriptions-item__label" label="学生姓名">
          {{ formData.stuName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="学生性别">
          {{ ['未知', '男', '女'][formData.sexType] }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="学生电话">
          {{ formData.fixedPhone }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="学生学号">
          {{ formData.stuNo }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="学生专业">
          {{ formData.majorName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="学生院系">
          {{ formData.collegeName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="课时">
          {{ formData.classHourName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="日期">
          {{ formData.day }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="咨询室校区">
          {{ formData.campusName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="学生自述">
          {{ formData.stuRemark }}
        </el-descriptions-item>
        <template v-if="type==='view'">
          <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="就诊状态">
            {{ statusList.length && (statusList.find(item => +item.paramValue === +formData.status).paramName) }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="教师备忘">
            {{ formData.teaRemark }}
          </el-descriptions-item>
        </template>
      </el-descriptions>
      <el-form class="mt-1" v-if="type==='edit'&&($store.state.login.userInfo.id===formData.teaUserId)" ref="form"
               :model="formData" :rules="rules"
               label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="状态">
              <tool-tips-group is-flex tips-content="已就诊、已取消状态的不可再修改。">
                <el-select
                  :disabled="title.status===2||title.status===3"
                  v-model="formData.status">
                  <el-option
                    v-for="{paramValue,paramName} in statusList"
                    :key="paramValue"
                    :label="paramName"
                    :value="+paramValue" />
                </el-select>
              </tool-tips-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="教师备忘" prop="teaRemark">
          <el-input type="textarea" rows="3" v-model.trim="formData.teaRemark" />
        </el-form-item>
      </el-form>
      <template #footer>
        <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
        <el-button v-if="$store.state.login.userInfo.id===formData.teaUserId" type="primary"
                   @click="save('就诊记录',savePsychoStuRecordApi)">确 定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import {
  getPsychoStuRecordByIdApi,
  savePsychoStuRecordApi
} from '@/api/student-manage/psychology-stu-record-api'

export default {
  name: 'psychoStuRecordDialog',
  mixins: [dialog],
  props: {
    statusList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      type: '',
      formData: {
        id: '',
        campusName: '',
        classHourName: '',
        className: '',
        collegeName: '',
        day: '',
        endTime: '',
        fixedPhone: '',
        idNumber: '',
        majorName: '',
        psychologyRoomId: '',
        remarks: '',
        roomAddress: '',
        roomName: '',
        scheduleId: '',
        schoolYear: '',
        sexType: 0,
        startTime: '',
        status: 0,
        stuName: '',
        stuNo: '',
        stuRemark: '',
        stuUserId: '',
        teaName: '',
        teaRemark: '',
        teaUserId: '',
        term: '',
        timeName: '',
        weekCount: 0,
        weekDayName: ''
      }
    }
  },
  async mounted() {
    this.$nextTick(async () => {
      if (this.id) {
        await this.getDataById(this.id, getPsychoStuRecordByIdApi)
      }
    })
  },
  methods: {
    savePsychoStuRecordApi // 保存api
  }
}
</script>
