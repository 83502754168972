// 学生问诊记录
import request from '@/service/request'

/* 学生问诊记录，分页
* @params
* */
export const pagePsychoStuRecordApi = (params) => {
  return request.post('/stu/psychologyStuOrderRecord/page', params)
}

/* 学生问诊记录，list
* @params
* */
export const listPsychoStuRecordApi = () => {
  return request.post('/stu/psychologyStuOrderRecord/list')
}

/* 学生问诊记录，根据id获取
* @params
* */
export const getPsychoStuRecordByIdApi = (id) => {
  return request.post(`/stu/psychologyStuOrderRecord/get/${id}`)
}

/* 学生问诊记录，删除
* @params
* */
export const delPsychoStuRecordByIdApi = (id) => {
  return request.post(`/stu/psychologyStuOrderRecord/del/${id}`)
}

/* 学生问诊记录，保存
* @params
* */
export const savePsychoStuRecordApi = (params) => {
  return request.post('/stu/psychologyStuOrderRecord/save', params)
}
